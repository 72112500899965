import { CALL_API2 } from "../Utils/const";
import { getAuthToken } from "../context/auth-context";

export async function getAllSeguros() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/seguros`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}
export async function saveSeguro(sucursalObjeto) {
    const { isNew, idseguros } = sucursalObjeto;
    const token = getAuthToken();
    let urlSend = `${CALL_API2}/seguros`;
  
    let method = "POST";
  
    if (sucursalObjeto.isNew) delete sucursalObjeto.isNew;
    if (sucursalObjeto.idseguros) delete sucursalObjeto.idseguros;
  
    if (!isNew) {
      urlSend = `${urlSend}/${idseguros}`;
      method = "PUT";
    }
  
    const result = await fetch(urlSend, {
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sucursalObjeto),
    });
  
    const data = await result.json();
  
    if (!result.ok) {
      throw new Error(data.message);
    }
  
    return data.isSaved;
  }
  /*
export async function getSucursalById(idSucursal) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/sucursal/${idSucursal}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}



export async function deleteSucursal(idSucursal) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/sucursal/${idSucursal}`, {
    headers: { Authorization: `Bearer ${token}` },
    method: "DELETE",
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.isDeleted;
}
*/