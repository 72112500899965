import React, {
    Fragment,
    useCallback,
    useEffect,
    useReducer,
    useState,
  } from "react";import { Link } from "react-router-dom";
  import Swal from "sweetalert2";
  import ModalSeguros from "../sis/ModalOpciones/ModalSeguros"
  import { getAllSeguros, saveSeguro } from "../../../Api/Seguros";
  import { Col, Card, Table, Button, } from "react-bootstrap";
  const showReducer = (curShow, state) => {
    switch (state.type) {
      case "SHOW":
        return {
          isShow: true,
          onReturnValue: state.onReturnValue,
        };
      case "SHOW2":
        return {
          isShow2: true,
          onReturnValue: state.onReturnValue,
        };
      case "END":
        return { ...curShow, isShow: false, isShow2: false };
      default:
        return { ...curShow, isShow: false };
    }
  };
  const Oseguros = () => {
    const [defaultValue, setRow] = useState([]);
    const [reducer, dispatchReducer] = useReducer(showReducer, {
      isShow: false,
      isShow2: false,
      TimbradoObject: null,
      onReturnValue: null,
    });
    const [listSucursal, setListSucursal] = useState([]);
    const onLoadValuesSucursal = useCallback(async () => {
      try {
        const listResult = await getAllSeguros();
        setListSucursal([...listResult]);
      } catch (err) {
        console.log(err);
      }
    });
    useEffect(() => {
      onLoadValuesSucursal();
    }, []);
    const onAgregarSucursal = () => {
      setRow("");
      dispatchReducer({
        type: "SHOW",
      });
    };
    const onCloseModal = () => {
      dispatchReducer({ type: "END" });
    };
  
  
    const onReturnSucursaldata = async (data,isNew) => {
      dispatchReducer({ type: "END" });
      try {
        const result = await saveSeguro({ ...data, isNew});
  
        if (result) {
          if (result) {
            Swal.fire({
              title: "Seguro Cargado",
              html: `Se cargo correctalmente el Seguro`,
              icon: "success",
              confirmButtonColor: 'black',
            });
            const listResult = await getAllSeguros();
            setListSucursal([...listResult]);
          }
        }
      } catch (err) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `${err}`,
        });
      }
    };
    const OnRowClick = (rowData) => {
      setRow(rowData)
      dispatchReducer({
        type: "SHOW2",
      });
    };
  
    return (
  
      <Fragment>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Seguros</Card.Title><Button onClick={onAgregarSucursal}>Agregar</Button>
            </Card.Header>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>ID</strong>
                    </th>
                    <th>
                      <strong>Seguro</strong>
                    </th>
                    <th>
                      <strong>Plan </strong>
                    </th>
                    <th>
                      <strong>Telefono</strong>
                    </th>
                    <th>
                      <strong>Opciones</strong>
                    </th>
  
                  </tr>
                </thead>
                <tbody>
                  {listSucursal.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td><h3>{item.idseguros}</h3></td>
                        <td><h3>{item.nombre}</h3></td>
                        <td><h3>{item.direccion}</h3></td>
                        <td><h3>{item.telefono}</h3></td>
                        <td >
                        <Link
                          href="#"
                          className="btn btn-primary shadow btn-xs sharp "
                          onClick={() => OnRowClick(item)}
                        >
                          <i className="fas fa-pencil-alt"></i>
                        </Link>
                      </td>
                      </tr>
                    );
                  })}
  
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        {reducer.isShow && (
          <ModalSeguros
            estado={reducer.isShow}
            onCloseModal={onCloseModal}
            onReturnValue={onReturnSucursaldata}
            defaultValue={defaultValue}
            isNew={true} // es nuevo
          />
        )}
        {reducer.isShow2 && (
          <ModalSeguros
            estado={reducer.isShow2}
            onCloseModal={onCloseModal}
            onReturnValue={onReturnSucursaldata}
            defaultValue={defaultValue}
            isNew={false} // es editado
          />
        )}
      </Fragment>
    )
  }
  export default Oseguros;