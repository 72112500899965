import {  useCallback, useEffect, useReducer, useState ,Fragment,useRef} from "react";
import {getAllFacturasCreditoNodepositados,updateFactura} from '../../../Api/facturacion'
import { DownloadTableExcel } from 'react-export-table-to-excel';
import {
    Col,
    Card,
    Table,
    Button
  } from "react-bootstrap";
import ModalDepositar from "../facturascontado/ModalDepositar/ModalDepositar";
import { Link } from "react-router-dom";
import { TailSpin } from  'react-loader-spinner'
import { getAllFormaPago } from "../../../Api/formaPago"
import Swal from 'sweetalert2'
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        onReturnValue: state.onReturnValue,
        ListFDP: state.ListFDP,
      };
      case "SHOW2":
          return {
              isShow: false,
            isShow2: true,
            onReturnValue: state.onReturnValue,
          };
    case "END":
      return { ...curShow, isShow: false, isShow2: false };

  }
};
const Vencimiento = () => {
  const VenColor=useRef(false);
  const [listFormadepago, setListFormadepago] = useState([]);
  const [userinfo, setUserInfo] = useState({
    idFac: [],
    response: [],
  });
  const [loading, setLoading] = useState(true);
  const [data, setdata] = useState([]);
    const chackbox = document.querySelectorAll(".bs_exam_topper input");
    const motherChackBox = document.querySelector(".bs_exam_topper_all input");
    const handleChange = (e) => {
      // Destructuring
      const { value, checked } = e.target;
      const { idFac } = userinfo;
      // Case 1 : The user checks the box
      if (checked) {
        setUserInfo({
          idFac: [...idFac, value],
          response: [...idFac, value],
        });
      }
    
      // Case 2  : The user unchecks the box
      else {
        setUserInfo({
          idFac: idFac.filter((e) => e !== value),
          response: idFac.filter((e) => e !== value),
        });
      }
    };
    
      const [reducer, dispatchReducer] = useReducer(showReducer, {
        isShow: false,
        isShow2:false,
        ListFDP: null,
        onReturnValue: null,
      });
      const Depositar = () => {
        
          dispatchReducer({
              type: "SHOW",
          });
      };
    const onCloseModal = () => {
        dispatchReducer({ type: "END" });
      };
      const assigmentValue = useCallback(async () => {
        try {
         
          const result = await getAllFacturasCreditoNodepositados();
          const listResut3 = await getAllFormaPago()
          const resultMapeado = listResut3.map((item) => {
                  return { label: item.nombre, value: item.nombre };
            });
          let resulting = resultMapeado.filter(item => item.label!="Efectivo")
          setListFormadepago(resulting);
          setdata(result);
          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      });
      useEffect(() => {
        assigmentValue();
      }, []);
      const tableRef = useRef(null);
      function unCheck() {
        var x = document.getElementsByClassName("form-check-input");
        for(let i=0; i<x.length; i++) {
           x[i].checked = false;
         }   
      }
      const onReturnValue = async (data1,data2) => {
     
        try {
          Swal.fire({
            title: 'Favor Aguarde',
            html: 'Actualizando datos',// add html attribute if you want or remove
            allowOutsideClick: false,
             showCancelButton: false,
            showConfirmButton: false,
        });
          for (let i = 0; i < userinfo.idFac.length; i++) {
        
            data1.idFactura=userinfo.idFac[i]
            data2.idFactura=userinfo.idFac[i]
             const result = await  updateFactura(data1);
             const result2 = await  updateFactura(data2);
          }
          const result3 = await getAllFacturasCreditoNodepositados();
          setdata(result3);
          dispatchReducer({ type: "END" });
          unCheck();
          Swal.close();
              
             
        } catch (err) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${err}`,
          });
        }
     
    };
    return (
      
        <Fragment>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Facturas Credito no Pagadas</Card.Title> <h5>
                        
                        <Button   onClick={Depositar}>Depositar Seleccionados</Button>
                      </h5>
            </Card.Header>
            <Card.Body>
            <DownloadTableExcel
                    filename="Facturas No depositadas "
                    sheet="users"
                    currentTableRef={tableRef.current}
                >

                   <Button  className="mb-4"> Export excel </Button>

                </DownloadTableExcel>
                <TailSpin
                height="30"
                width="30"
                color="Black"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
                visible={loading}
                />
                <br></br>
              <Table responsive ref={tableRef}>
                <thead>
                  <tr>
                    <th className="width50 ">
                    
                    </th>
                    <th>
                      <strong>ID Factura</strong>
                    </th>
                    <th>
                      <strong>Factura</strong>
                    </th>
                    
                    <th>
                      <strong>Ruc</strong>
                    </th>
                    <th>
                      <strong>Razon Social</strong>
                    </th>
                    <th>
                      <strong>Monto</strong>
                    </th>
                    <th>
                      <strong>Vencimiento</strong>
                    </th>
                    <th>
                      <strong>Fecha</strong>
                    </th>
                    <th>
                      <strong>Ver Factura</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
             
                {data.map((data, index) => {
                 if (data.fechapagovencimiento>new Date())
                 {
                  VenColor.current=false
                 }else
                {
                  VenColor.current=true
                }
                   return (
                  <tr  key={index}>
                    <td>
                      <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                      <input
                          className="form-check-input"
                          type="checkbox"
                          value={data.idFactura}
                          id="flexCheckDefault"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox2"
                        ></label>
                      </div>
                    </td>
                    
                    <td>{data.idFactura}</td>
                    <td> {data.pf1}-{data.pf2}-{data.numerofactura}</td>
                    <td>{data.ruc}</td>
                    <td>{data.razonsocial}</td>
                    <td> {data.totalFactura}</td>
                    <td style={{color: !VenColor.current ? "red" : ""}}> {data.fechapagovencimiento}</td>
                    <td> {data.fechafactura}</td>
                    <td>
                      <Link  to= {`/FichaFactura/${data.idFactura}`}>Ver Factura </Link></td>
                   
                  </tr>
                   )
                  })}
                 
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
           {reducer.isShow && (
            <ModalDepositar
            listFormadepago={listFormadepago}
              estado={reducer.isShow}
              onCloseModal={onCloseModal}
              onReturnValue={onReturnValue}
              listID={userinfo}
            />
          )}  
          </Fragment>
    )
}
export default Vencimiento;