
import { jsPDF } from "jspdf";
import fondoenfermeria from "./Enfermería en tu Hogar S.A..jpg"
export default function genPDF({ datosfactura, detallefactura, isCredito, TextoNumber, sum1, sum2 }) {
  const doc = new jsPDF({
    unit: 'px',
    format: 'A6'
  });
  doc.addImage(`${fondoenfermeria}`, "jpg", 0, 0, 223, 318);
  if (datosfactura.direccion == null) {
    datosfactura.direccion = ""
  }
  if (datosfactura.telefono == null) {
    datosfactura.telefono = ""
  }
  datosfactura.iva5 = datosfactura.iva5 ? datosfactura.iva5 : datosfactura.gravado05
  datosfactura.iva10 = datosfactura.iva10 ? datosfactura.iva10 : datosfactura.gravado10
  datosfactura.totalmonto = datosfactura.totalmonto ? datosfactura.totalmonto : datosfactura.totalFactura
  datosfactura.ivatotal = datosfactura.ivatotal ? datosfactura.ivatotal : Number(datosfactura.gravado05 + datosfactura.gravado10)
  datosfactura.Subtotal5 = datosfactura.Subtotal5 ? datosfactura.Subtotal5 : sum1
  datosfactura.Subtotal10 = datosfactura.Subtotal10 ? datosfactura.Subtotal10 : sum2
  datosfactura.fechafactura = datosfactura.fechafactura ? datosfactura.fechafactura : new Date().toLocaleDateString()
  const writePage = (offsetX = 0) => {
  
    /* Separacion entre item del listado*/
    let gapRow = 20;

    /* Cambiar segun booleano para saber si es Contado o Credito */
    let isContado = isCredito
    let casillaX = offsetX + 188 + (isContado ? 20 : 0);

    doc.setFont(undefined, 'bold')
    doc.setFontSize(8)
    /* Casilla */
    doc.text("X", casillaX + 3, 34)
    /*timbrado*/
    doc.setFontSize(7)
    doc.text(`Timbrado:${datosfactura.timbrado}`, 122, 8)
    /*numero de factura*/
    doc.setFontSize(8)
    doc.text(`00${datosfactura.pf1}-00${datosfactura.pf2}-${datosfactura.numerofactura}`, 150, 25)
    doc.setFontSize(6)
    doc.setFont(undefined, 'normal')

    /* Fecha de emision */
    doc.text(datosfactura.fechafactura, offsetX + 35, 34)

    /* Razon Social */

    doc.text(datosfactura.razonsocial, offsetX + 44, 41)

    /* RUC / CI */
    doc.text(datosfactura.ruc, offsetX + 32, 47)

    /* Direccion */
    doc.setFontSize(5)
    doc.text(datosfactura.direccion, offsetX + 93, 47)
    doc.setFontSize(8)
    /*tel*/
    doc.setFontSize(5)
    doc.text(datosfactura.telefono, offsetX + 183, 47)
    doc.setFontSize(8)
    /*Bucle para iterar lista de productos */
    for (const [index, obj] of detallefactura.entries()) {
      addRow(obj, offsetX - 3, 65 + (index * gapRow))
    }

    /* Subtotal 5 */
    doc.text(String(Math.round(datosfactura.Subtotal5)), offsetX + 230, 406 + 1)

    /* Subtotal 10 */
    doc.text(String(Math.round(datosfactura.Subtotal10)), offsetX + 270, 406 + 1)

    /* Total a pagar en letras */
    doc.setFontSize(6)
    doc.text(TextoNumber, offsetX + 44, 294,
      { maxWidth: 130 })
    /* Total a pagar en numeros */
    doc.setFontSize(7)
    doc.text(parseInt(datosfactura.totalmonto).toLocaleString('es'), offsetX + 186, 297)

    /* Total iva5 */
    doc.text(parseInt(datosfactura.iva5).toLocaleString('es'), offsetX + 65, 306)

    /* Total iva10 */
    doc.text(parseInt(datosfactura.iva10).toLocaleString('es'), offsetX + 114, 306)

    /* Total Total iva */
    doc.text(parseInt(datosfactura.ivatotal).toLocaleString('es'), offsetX + 168, 306)
  }

  /* Funcion que añade filas a al listado pdf */
  const addRow = (data, x = 0, y) => {
    doc.setFontSize(6)
    doc.text(String(data.cantidad), x + 10, y)
    doc.text(String(data.artcod), x + 23, y)
    doc.setFontSize(7)
    doc.text(data.producto, x + 44, y,
      {
        maxWidth: 70,
      }
    )
    doc.text(parseInt(data.precio).toLocaleString('es'), x + 118, y)
    doc.text(String(data.exenta), x + 125, y)
    doc.text(parseInt(data.cinco).toLocaleString('es'), x + 163, y)
    doc.text(parseInt(data.diez).toLocaleString('es'), x + 190, y)

  }



  writePage()
  /*doc.save()*/
  doc.setProperties({
    title: `00${datosfactura.pf1}-00${datosfactura.pf2}-${datosfactura.numerofactura}`
  });
  doc.save(`00${datosfactura.pf1}-00${datosfactura.pf2}-${datosfactura.numerofactura}`);
}
