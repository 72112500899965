import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState,
  useMemo,
} from "react";
import Swal from "sweetalert2";
import { Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalCostos from "../sis/ModalOpciones/ModalCostos";
import { getAllCostos, saveCostos, deleteCostos } from "../../../Api/Costos";
import { getUserCredentials } from "../../../Api/usuario";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { TailSpin } from "react-loader-spinner";
import { COLUMNS } from "./Columns";
import { GlobalFilter } from "./GlobalFilter";
import "./filtering.css";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        onReturnValue: state.onReturnValue,
      };
    case "SHOW2":
      return {
        isShow2: true,
        onReturnValue: state.onReturnValue,
        TimbradoObject: state.TimbradoObject,
      };
    case "END":
      return { ...curShow, isShow: false, isShow2: false };
    default:
      return { ...curShow, isShow: false };
  }
};
const Oformasdepago = () => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  const [defaultValue, setRow] = useState([]);
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    isShow2: false,
    TimbradoObject: null,
    onReturnValue: null,
  });
  const [data, setData] = useState([]);

  const onLoadValuesTimbrado = useCallback(async () => {
    try {
      const listResult = await getAllCostos();
      const result2 = await  getUserCredentials();
      setUserData(result2)
      setData([...listResult]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    onLoadValuesTimbrado();
  }, []);

  const columns = useMemo(() => COLUMNS, []);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {pageSize: 30, pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const onAgregarTimbrado = () => {
    let row = [{ ivavalor: 10 }];
    setRow(row[0]);
    dispatchReducer({
      type: "SHOW",
    });
  };
  const { globalFilter, pageIndex } = state;
  const Delete = (id) => {
    try {
      Swal.fire({
        title: "Estas seguro/a de Eliminar?",
        text: "No es Reversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "black",
        cancelButtonColor: "red",
        confirmButtonText: "Si! Eliominar",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Cargando",
            html: "Cargando  datos", // add html attribute if you want or remove
            allowOutsideClick: false,
            showCancelButton: false,
            showConfirmButton: false,
          });
          deleteCostos(id);

          onLoadValuesTimbrado();
          Swal.close();
          Swal.fire({
            title: "Eliminado",
            text: "Producto Eliminado",
            icon: "warning",
            confirmButtonColor: "black",
          });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const onReturnTimbradodata = async (data, isNew) => {
    dispatchReducer({ type: "END" });
    try {
      const result = await saveCostos({ ...data, isNew });

      if (result) {
        Swal.fire({
          title: "Cargando",
          html: "Cargando  datos", // add html attribute if you want or remove
          allowOutsideClick: false,
          showCancelButton: false,
          showConfirmButton: false,
        });
        const listResult = await getAllCostos();
        setData([...listResult]);
        Swal.close();

        if (result) {
          Swal.fire({
            title: "Servicio/Producto agregado",
            html: `Producto/Servicio Agregado Correctamente`,
            icon: "success",
            confirmButtonColor: "black",
          });
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };
  const Editar = (item) => {
    setRow(item);
    dispatchReducer({
      type: "SHOW2",
    });
  };

  return (
    <Fragment>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Servicios/Productos</Card.Title>
            {userData.IdUsuario=== 103 && (
            <Button onClick={onAgregarTimbrado}>Agregar</Button>
            )}
          </Card.Header>
          <Card.Body>
            <div className="chart-wrapper">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <GlobalFilter
                      filter={globalFilter}
                      setFilter={setGlobalFilter}
                    />
                    <TailSpin
                      height="30"
                      width="30"
                      color="Black"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={loading}
                    />
                    <br></br>
                    <table
                      {...getTableProps()}
                      style={{
                        overflowX: "auto",
                        width: "100%",

                        borderRadius: "10px",
                        textAlign: "center",
                      }}
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              <th {...column.getHeaderProps()}>
                                {column.render("Header")}
                                {column.canFilter
                                  ? column.render("Filter")
                                  : null}
                              </th>
                            ))}
                            <th style={{ padding: "20px" }}>Opciones</th>
                          </tr>
                        ))}
                      </thead>

                      <tbody {...getTableBodyProps()} className="">
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()}>
                              {row.cells.map((cell) => {
                                return (
                                  <td {...cell.getCellProps()} className="p-2">
                                    {cell.render("Cell")}{" "}
                                  </td>
                                );
                              })}
                              <td>
                              {userData.IdUsuario=== 103 && (
                                <div className="d-flex ml-5" >
                                
                                  <Link
                                    href="#"
                                    className="btn btn-primary shadow btn-xs sharp me-1"

                                  >
                                    <i onClick={()=>Editar(row.values)}className="fas fa-pencil-alt"></i>
                                  </Link>
                                  <Link
                                    href="#"
                                    className="btn btn-danger shadow btn-xs sharp"
                                  >
                                    <i onClick={()=>Delete(row.values.idcostos)} className="fa fa-trash"></i>
                                  </Link>
                               
                                </div>
                                 )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-between">
                      <span>
                        Page{" "}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>
                        {""}
                      </span>
                      <span className="table-index">
                        Go to page :{" "}
                        <input
                          type="number"
                          className="ml-2"
                          defaultValue={pageIndex + 1}
                          onChange={(e) => {
                            const pageNumber = e.target.value
                              ? Number(e.target.value) - 1
                              : 0;
                            gotoPage(pageNumber);
                          }}
                        />
                      </span>
                    </div>
                    <div className="text-center mb-3">
                      <div className="filter-pagination  mt-3">
                        <button
                          className=" previous-button"
                          onClick={() => gotoPage(0)}
                          disabled={!canPreviousPage}
                        >
                          {"<<"}
                        </button>

                        <button
                          className="previous-button"
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        >
                          Previous
                        </button>
                        <button
                          className="next-button"
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        >
                          Next
                        </button>
                        <button
                          className=" next-button"
                          onClick={() => gotoPage(pageCount - 1)}
                          disabled={!canNextPage}
                        >
                          {">>"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      {reducer.isShow2 && (
        <ModalCostos
          estado={reducer.isShow2}
          onCloseModal={onCloseModal}
          defaultValue={defaultValue}
          onReturnValue={onReturnTimbradodata}
          isNew={false} // 1 es editado
        />
      )}
      {reducer.isShow && (
        <ModalCostos
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          onReturnValue={onReturnTimbradodata}
          defaultValue={defaultValue}
          isNew={true} // 0 es nuevo
        />
      )}
    </Fragment>
  );
};
export default Oformasdepago;
