import React, {
  useEffect,
  useState,
  useCallback,
  useReducer,
  useRef,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import {
  getFacturaCompraById,
  anularFacturaCompra,
  getDetalleFacturaCompraId,
} from "../../../Api/facturacion";
import Swal from "sweetalert2";
import { TailSpin } from "react-loader-spinner";
import { getUserCredentials } from "../../../Api/usuario";
var myStyle = {
  height: "8%",
};
var myStyle2 = {
  borderTop: "1px solid #222",

  height: "10%",
};
var myStyle3 = {
  borderTop: "1px solid #222",

  height: "66%",
};

var myStyle5 = {
  borderTop: "1px solid #222",
  height: "4%",
};
var myStyle6 = {
  height: "4%",
};

var myStyleex = {
  borderTop: "1px solid #222",

  height: "2%",
};
var myStyleex2 = {
  borderTop: "1px solid #222",

  height: "3%",
};
var myStyleex2venta = {
  borderTop: "1px solid #222",

  height: "2%",
};
var myStylh1 = {
  borderRight: "1px solid #222",
  width: "6%",
  fontSize: 8,
};
var myStylh2 = {
  borderRight: "1px solid #222",
  width: "8%",
  fontSize: 8,
};
var myStylh3 = {
  borderRight: "1px solid #222",
  width: "40%",
  fontSize: 8,
};
var myStylh4 = {
  borderRight: "1px solid #222",
  width: "10%",
  fontSize: 8,
};
var myStylh5 = {
  borderRight: "1px solid #222",
  width: "7%",
  fontSize: 8,
};
var myStylh6 = {
  borderRight: "1px solid #222",
  width: "14%",
  fontSize: 8,
};
var myStylh7 = {
  width: "14%",
  fontSize: 8,
};
var myStyleventa = {
  borderRight: "1px solid #222",
  width: "64%",
  fontSize: 8,
};
var myStyleventa2 = {
  width: "37%",
  fontSize: 8,
};
var myStylSubtotal = {
  borderRight: "1px solid #222",
  width: "64%",
  fontSize: 8,
};
var myStylSubtotal2 = {
  borderRight: "1px solid #222",
  width: "8%",
  fontSize: 12,
};
var myStylSubtotal3 = {
  borderRight: "1px solid #222",
  width: "14%",
  fontSize: 12,
};
var myStylSubtotal4 = {
  width: "14%",
  fontSize: 12,
};
var myStyltotal = {
  borderRight: "1px solid #222",
  width: "86%",
  fontSize: 10,
};
var myStyltotal2 = {
  width: "14%",
  fontSize: 12,
};
var myStylh11 = {
  width: "30px",
  fontSize: 12,
};
var myStylh12 = {
  width: "39px",
  fontSize: 12,
};
var myStylh13 = {
  width: "184px",
  fontSize: 12,
};
var myStylh14 = {
  width: "48px",
  fontSize: 12,
};
var myStylh15 = {
  width: "36px",
  fontSize: 12,
};
var myStylh16 = {
  width: "66px",
  fontSize: 12,
};
var myStylh17 = {
  width: "66px",
  fontSize: 12,
};
var myStylex1 = {
  borderRight: "1px solid #222",
  width: "50%",
  fontSize: 12,
};
var myStylex2 = {
  width: "50%",
  fontSize: 30,
  color: "Red",
};
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        onReturnValue: state.onReturnValue,
        ListFDP: state.ListFDP,
      };
    case "SHOW2":
      return {
        isShow: false,
        isShow2: true,
        onReturnValue: state.onReturnValue,
      };
    case "END":
      return { ...curShow, isShow: false, isShow2: false };
  }
};

const FichaFacturaCompra = () => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    isShow2: false,
    ListFDP: null,
    onReturnValue: null,
  });
  const location = useLocation();
  const {id} = useParams()
  const [id2, setid2] = useState(id);
  const [datosfacturas, setFactura] = useState([]);
  const [iva10total, setiva10total] = useState();
  const [iva5total, setiva5total] = useState();
  const [detallefactura, setDetalle] = useState([]);

  const Ncomprobante = useRef("NO");
  const numberToTextss = require("../TextoNumero");

  const assigmentValue = useCallback(async () => {
    try {
      const listResult = await getFacturaCompraById(id2); // de compra
      const listResult2 = await getDetalleFacturaCompraId(id2); // de compra
     const iva10array = listResult2.filter(user => user.iva=='10')
     const iva5array = listResult2.filter(user => user.iva=='5')
     let totaliva10i=0
     iva10array.forEach(function(a){totaliva10i += a.total;});
        setiva10total(totaliva10i)
        let totaliva5i=0
        iva5array.forEach(function(a){totaliva5i += a.total;});
           setiva5total(totaliva5i)
      const result2 = await getUserCredentials();
      setUserData(result2);

      Ncomprobante.current = listResult[0].numerofactura;

      setFactura(listResult);
      setDetalle(listResult2);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [id]);
  useEffect(() => {
    assigmentValue();
  }, [assigmentValue]);
  const AnularCompra = () =>  {
    try{
        Swal.fire({
            title: 'Estas seguro/a de Anular?',
            text: "No es Reversible!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'black',
            cancelButtonColor: 'red',
            confirmButtonText: 'Si! anular'
          }).then((result) => {
            if (result.isConfirmed) {
                (async () => {
                    const msg = await anularFacturaCompra(id)
                    console.log(msg)
                    assigmentValue()
                    Swal.fire({
                      title: 'Anulado',
                      text: `${msg}`,
                      icon: 'warning',
                      confirmButtonColor: 'black',
                     
                    })
                    })()
                   
                  
              
            }
          })
  
        }
        catch (err) {
            console.log(err);
        }
    }
  return (
    <>
      <TailSpin
        height="80"
        width="80"
        color="Black"
        ariaLabel="tail-spin-loading"
        radius="1"
        wrapperStyle={{}}
        wrapperClass=""
        visible={loading}
      />
      {datosfacturas.map((datosfactura, index2) => {
        return (
          <div className="row">
          <div className="col-md-9 col-xl-5">
           
          <div className="card">
         
          <div className="card-body pb-0">
          <h2>Factura de Compra</h2>
          <div key={index2}>
           
              <div className="col-xl-5 col-xxl-6 mb-4">
                <div
                  style={{
                    border: "1px solid black",
                    width: "12.5cm",
                    height: "18.45cm",
                    background: "white",
                  }}
                >
                  <div
                    className="d-flex justify-content-between mt-1 ml-1"
                    style={myStyle}
                  >
                    <div className="row" style={myStylex1}>
                      <span style={{ fontSize: 12 }}>
                        Numero Factura:
                        <br></br> {datosfactura.numerofactura}{" "}
                      </span>
                    </div>
                  </div>
                  <div style={myStyle2}>
                    <div className="d-flex justify-content-between mt-1 ml-1">
                      <span style={{ fontSize: 10 }}>
                        Fecha Emision: {datosfactura.fechafactura}{" "}
                      </span>
                    </div>

                    <div className="d-flex flex-row mt-1">
                      <span className="ml-1" style={{ fontSize: 10 }}>
                        Emisor: {datosfactura.razonsocial}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between mt-1 ml-1">
                      <span style={{ fontSize: 10 }}>
                        RUC Emisor: {datosfactura.ruc}
                      </span>

                      <div></div>
                    </div>
                  </div>
                  <div
                    style={myStyleex2venta}
                    className="d-flex align-items-stretch"
                  >
                    <div className="ml-1" style={myStyleventa}></div>
                    <div
                      className="d-flex justify-content-center"
                      style={myStyleventa2}
                    >
                      <span>Venta</span>
                    </div>
                  </div>
                  <div style={myStyleex} className="d-flex align-items-stretch">
                    <div className="ml-1" style={myStylh1}>
                      cant.
                    </div>
                    <div className="ml-1" style={myStylh2}>
                      ArtCod.
                    </div>
                    <div className="ml-1" style={myStylh3}>
                      Descripcion
                    </div>
                    <div className="ml-1" style={myStylh4}>
                      P. Unitario
                    </div>
                    <div className="ml-1" style={myStylh5}>
                      exentas
                    </div>
                    <div className="ml-1" style={myStylh6}>
                      5%
                    </div>
                    <div className="ml-1" style={myStylh7}>
                      10%
                    </div>
                  </div>
                  <table
                    style={myStyle3}
                    className="d-flex align-items-stretch"
                  >
                    <tbody
                      className=""
                      style={{ height: "100%", width: "100%" }}
                    >
                      {detallefactura.map((detallefactura, index) => {
                        let iva10 = "";
                        let iva5 = "";
                     
                        detallefactura.iva === "10"
                          ? (iva10 = detallefactura.total)
                          : (iva5 = detallefactura.total);

                        return (
                          <tr
                            key={index}
                            style={{ height: "100%", width: "100%" }}
                          >
                            <td style={myStylh11}>
                              <span className="d-flex justify-content-center">
                                {" "}
                                {detallefactura.cantidad}{" "}
                              </span>
                            </td>
                            <td style={myStylh12}>
                              <span className="d-flex justify-content-center">
                                {" "}
                                {detallefactura.artcod}{" "}
                              </span>
                            </td>
                            <td style={myStylh13}>
                              <span> {detallefactura.producto} </span>
                            </td>
                            <td style={myStylh14}>
                              <span> {detallefactura.precio} </span>
                            </td>
                            <td style={myStylh15}>
                              <span>{detallefactura.exenta}</span>
                            </td>
                            <td style={myStylh16}>
                              <span>{iva5}</span>
                            </td>
                            <td style={myStylh17}>
                              <span>{iva10}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <div
                    style={myStyleex2}
                    className="d-flex align-items-stretch"
                  >
                    <div
                      className="d-flex justify-content-left"
                      style={myStylSubtotal}
                    >
                      Sub Total
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={myStylSubtotal2}
                    >
                      <span></span>
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={myStylSubtotal3}
                    >
                      <span>{iva5total}</span>
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={myStylSubtotal4}
                    >
                      <span>{iva10total}</span>
                    </div>
                  </div>
                  <div style={myStyle5} className="d-flex align-items-stretch">
                    <div
                      className="d-flex justify-content-left"
                      style={myStyltotal}
                    >
                      Total a pagar(en letas){" "}
                      <span style={{ fontSize: 9 }}>
                        {numberToTextss.NumerosALetras(
                          datosfactura.totalFactura
                        )}{" "}
                        Guaranies---------
                      </span>{" "}
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={myStyltotal2}
                    >
                      {datosfactura.totalFactura}
                    </div>
                  </div>
                  <div style={myStyle6}>
                    <div
                      style={myStyle5}
                      className="d-flex justify-content-around "
                    >
                      <div
                        className="d-flex justify-content-left mt-1"
                        style={myStyltotal}
                      >
                        LIQUIDACIOON DEL IVA (5%){" "}
                        <span> {datosfactura.iva5total}</span>
                      </div>
                      <div
                        className="d-flex justify-content-center mt-1"
                        style={myStyltotal}
                      >
                        (10%) {datosfactura.iva10total}
                      </div>
                      <div
                        className="d-flex justify-content-center mt-1"
                        style={myStyltotal}
                      >
                        Total Iva:{" "}
                        {datosfactura.iva5total + datosfactura.iva10total}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          </div>
          </div>
          <div className="col-xl-5 col-xxl-5">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="card-title">Datos Extras</h4>
                        <Button onClick={AnularCompra} variant="danger">Anular</Button>
                      </div>
                      <div className="card-body p-4">
                        <span>
                          Tipo de Factura : {datosfactura.tipoFactura}
                        </span>
                        <br></br>

                        <span>Obs : {datosfactura.formadepago} </span>
                        <br></br>
                        <span>
                          Destino de Compra : {datosfactura.destinocompra}{" "}
                        </span>
                        <br></br>
                        <span>
                          Estado : {datosfactura.estado}{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row"></div>
              </div>
          </div>
        );
      })}
    </>
  );
};
export default FichaFacturaCompra;
