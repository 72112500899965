import React, { Fragment, useCallback, useEffect, useState, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button, } from "react-bootstrap";
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { COLUMNS } from './Columns';
import { GlobalFilter } from './GlobalFilter';
import './filtering.css';
import { getAllfacturasContadoPagados } from '../../../Api/facturacion'
import { getAllFormaPago } from '../../../Api/formaPago'
import Select from "react-select";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { TailSpin } from 'react-loader-spinner'
const Depositado = () => {
  const [loading, setLoading] = useState(true);
  const [filterData, setValue] = useState([]);
  const [data, setdata] = useState([]);
  const [FDP, setFDP] = useState([]);
  const columns = useMemo(() => COLUMNS, [])
  const assigmentValue = useCallback(async () => {
    try {
      const producto = {
        fechaincial: '1991-01-01',
        fechafinal: '1991-01-01',
        formadepago: 'TODOS'
      }

      const result = await getAllfacturasContadoPagados(producto);
      const result2 = await getAllFormaPago();
      const resultMapeado = result2.map((item) => {
        return { label: item.nombre, value: item.nombre };
      });
      setdata([...result]);
      setFDP([...resultMapeado, { value: 'TODOS', label: 'Todos' }])
      setValue({
        fechaincial: '1991-01-01',
        fechafinal: '1991-01-01'
      }
      )
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  });
  useEffect(() => {
    assigmentValue();
  }, []);


  const Filtrar = useCallback(async () => {
    try {
      setLoading(true);

      const result = await getAllfacturasContadoPagados(filterData);

      setdata([...result]);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  });



  const tableInstance = useTable({
    columns,
    data,
    initialState: { pageIndex: 0 }
  }, useFilters, useGlobalFilter, usePagination)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance


  const { globalFilter, pageIndex } = state

  const tableRef = useRef(null);

  return (

    <Fragment>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body pb-0">
            <div className="row justify-content-between">
              <div className="col-auto">
                <Card.Title>Facturas Contado Depositados</Card.Title>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body pb-0">
            <div className="row justify-content-between">
              <div className="col-auto">
                <Card.Title>Filtros</Card.Title>
              </div>
              <div className="chart-wrapper">
                <form
                  id="auFORM">
                  <Row className="mb-3">
                    <Col className="xl-4">
                      <div className="form-group mb-2 ">
                        <label className="col-form-label">
                          Fecha Inicial

                        </label>
                        <div className="">
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) => {
                              setValue({
                                ...filterData,
                                fechaincial: e.target.value,
                              });
                            }}



                          />
                        </div>
                      </div>

                    </Col>
                    <Col className="xl-4">
                      <div className="form-group mb-2  ">
                        <label className="col-form-label">
                          Fecha Final

                        </label>
                        <div className="">
                          <input
                            type="date"
                            className="form-control"
                            onChange={(e) => {
                              setValue({
                                ...filterData,
                                fechafinal: e.target.value,
                              });
                            }}


                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="xl-4">
                      <div className="form-group mb-2 ">
                        <label className="col-form-label">
                          Forma de pago

                        </label>
                        <div className="">
                          <Select
                            className="basic-single"
                            options={FDP}
                            onChange={(e) => {
                              setValue({
                                ...filterData,
                                formadepago: e.value,
                              });
                            }}
                          />
                        </div>
                      </div>


                    </Col>
                  </Row>
                  <Button className="mb-2" onClick={Filtrar}>Buscar</Button>
                </form>
              </div>
            </div>




          </div>

        </div>
      </div>
      <div className="col-md-12">
        <div className="card">
          <div className="card-body pb-0">
            <div className="row justify-content-between">
              <div className="col-auto">

              </div>
              <div className="col-auto">

              </div>
            </div>
          </div>
          <div className="chart-wrapper">
            <div className="card">
              <div className="card-body">
                <DownloadTableExcel
                  filename="users table"
                  sheet="users"
                  currentTableRef={tableRef.current}
                >

                  <Button className="mb-4"> Export excel </Button>

                </DownloadTableExcel>


                <div className="table-responsive">
                  <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
                  <TailSpin
                    height="30"
                    width="30"
                    color="Black"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={loading}
                  />
                  <br></br>
                  <table {...getTableProps()} className="table dataTable display" ref={tableRef}>
                    <thead>
                      {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                              {column.render('Header')}
                              {column.canFilter ? column.render('Filter') : null}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()} className=""  >

                      {page.map((row) => {
                        prepareRow(row)
                        return (
                          <tr  {...row.getRowProps()} >
                            {row.cells.map((cell) => {
                              return <td  {...cell.getCellProps()}> 
                              <Link
                               to= {`/FichaFactura/${row.values.idFactura}`}
                              >{cell.render('Cell')} </Link>
                              </td>
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-between">
                    <span>
                      Page{' '}
                      <strong>
                        {pageIndex + 1} of {pageOptions.length}
                      </strong>{''}
                    </span>
                    <span className="table-index">
                      Go to page : {' '}
                      <input type="number"
                        className="ml-2"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                          const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                          gotoPage(pageNumber)
                        }}
                      />
                    </span>
                  </div>
                  <div className="text-center mb-3">
                    <div className="filter-pagination  mt-3">
                      <button className=" previous-button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>

                      <button className="previous-button" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        Previous
                      </button>
                      <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                        Next
                      </button>
                      <button className=" next-button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


    </Fragment>
  )
}
export default Depositado;