import { CALL_API2 } from "../Utils/const";
import { getAuthToken } from "../context/auth-context";

export async function getAllTimbrados() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/timbrado`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.result;
}

export async function getTimbradoById(idTimbrado) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/timbrado/${idTimbrado}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}

export async function getActiveTimbrado() {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/timbrado-activo`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.result;
}

export async function getActiveTimbradoByUser() {
  const token = getAuthToken();
  const result = await fetch(`${CALL_API2}/timbrado-usuario`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await result.json();
  if (!result.ok) {
    throw new Error(data.message);
  }
  return data.result;
}

export async function getTimbradoByQuery(queryObject) {
  console.log(queryObject)
  const pf1= queryObject.Suc.current;
  const pf2= queryObject.PDV.current;
  const timbrado= queryObject.Tim.current;
  const token = getAuthToken();
  const result = await fetch(
    `${CALL_API2}/timbrado-query?pf1=${pf1}&pf2=${pf2}&timbrado=${timbrado}`,
    {
      headers: { Authorization: `Bearer ${token}` },
    }
  );
  const data = await result.json();
  return data.result;
}

export async function saveTimbrado(timbradoObject) {
  const { isNew, idtimbrado } = timbradoObject;
  const token = getAuthToken();
  let urlSend = `${CALL_API2}/timbrado`;

  let method = "POST";

  if (timbradoObject.isNew) delete timbradoObject.isNew;
  if (timbradoObject.idtimbrado) delete timbradoObject.idtimbrado;

  if (!isNew) {
    urlSend = `${urlSend}/${idtimbrado}`;
    method = "PUT";
  }

  const result = await fetch(urlSend, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(timbradoObject),
  });

  const data = await result.json();

  if (!result.ok) {
    throw new Error(data.message);
  }

  return data.isSaved;
}

export async function deleteTimbrado(idTimbrado) {
  const token = getAuthToken();
  const response = await fetch(`${CALL_API2}/timbrado/${idTimbrado}`, {
    method: "DELETE",
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }
  return data.isDeleted;
}
