import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import ModalPDV from "../sis/ModalOpciones/ModalPDV"
import {
  Col,
  Card,
  Table,
  Button,
} from "react-bootstrap";
import { getAllPuntoVenta, savePuntoVenta } from "../../../Api/puntoVenta";
import Swal from "sweetalert2";
const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        onReturnValue: state.onReturnValue,
      };
    case "SHOW2":
      return {
        isShow2: true,
        onReturnValue: state.onReturnValue,
      };
    case "END":
      return { ...curShow, isShow: false, isShow2: false };
    default:
      return { ...curShow, isShow: false };
  }
};
const Opuntodeventa = () => {
  const [defaultValue, setRow] = useState([]);
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    isShow2: false,
    TimbradoObject: null,
    onReturnValue: null,
  });
  const [listSucursal, setListSucursal] = useState([]);
  const onLoadValuesSucursal = useCallback(async () => {
    try {
      const listResult = await getAllPuntoVenta();
      setListSucursal([...listResult]);
    } catch (err) {
      console.log(err);
    }
  });
  useEffect(() => {
    onLoadValuesSucursal();
  }, []);
  const OnRowClick = (rowData) => {
    setRow(rowData)
    dispatchReducer({
      type: "SHOW2",
    });
  };

  const onAgregarSucursal = () => {
    setRow("");
    dispatchReducer({
      type: "SHOW",
    });
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };
  const onReturnSucursaldata = async (data,isNew) => {
    dispatchReducer({ type: "END" }); 
    try {
      const result = await savePuntoVenta({ ...data, isNew });

      if (result) {
        if (result) {
          Swal.fire({
            title: "Punto de venta cargado",
            html: `Se cargo correctalmente el punto de venta`,
            icon: "success",
            confirmButtonColor: 'black',
          });
          const listResult = await getAllPuntoVenta();
          setListSucursal([...listResult]);
          
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };


  return (

    <Fragment>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Punto de venta </Card.Title><Button onClick={onAgregarSucursal}>Agregar</Button>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>Codigo</strong>
                  </th>
                  <th>
                    <strong>Punto de venta</strong>
                  </th>
                  <th>
                    <strong>Opciones</strong>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listSucursal.slice(0, 11).map((item,index) => {
                  return (
                    <tr key={index}>
                      <td><h3>{item.codigo}</h3></td>
                      <td><h3>{item.nombre}</h3></td>

                      <td>
                        <Button onClick={() => OnRowClick(item)}>Editar</Button>
                      </td>

                    </tr>
                  );
                })}

              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      {reducer.isShow && (
        <ModalPDV
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          onReturnValue={onReturnSucursaldata}
          defaultValue={defaultValue}
          isNew={true} // es nuevo
        />
      )}
      {reducer.isShow2 && (
        <ModalPDV
          estado={reducer.isShow2}
          onCloseModal={onCloseModal}
          onReturnValue={onReturnSucursaldata}
          defaultValue={defaultValue}
          isNew={false} // es editado
        />
      )}
    </Fragment>
  )
}
export default Opuntodeventa;