import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
const options = [
  { value: "10", label: "10" },
  { value: "5", label: "5" },
];
const ModalCostos = (props) => {
  const { estado, onCloseModal, onReturnValue, defaultValue,isNew} = props;
  const [formData3, setValue] = useState(defaultValue);
  const [dis, setdis] = useState(false);
  const handleSubmit2 = (event) => {
    setdis(true);
    event.preventDefault();
    onReturnValue(formData3,isNew);
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Costo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2} id="auFORM">
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Codigo
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  value={formData3.COD}
                  className="form-control"
                  required
                  disabled={!isNew}
                  onChange={(e) => {
                    setValue({ ...formData3, COD: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Servicio/Producto
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  required
                   value={formData3.producto}
                  onChange={(e) => {
                    setValue({ ...formData3, producto: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Precio de Venta (IVA INCLUIDO)
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  required
                  value={formData3.precioventa}
                  onChange={(e) => {
                    setValue({ ...formData3, precioventa: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Precio de Compra (IVA INCLUIDO)
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  required
                  value={formData3.preciocompra}
                  onChange={(e) => {
                    setValue({ ...formData3, preciocompra: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-4 row">
              <label className="col-form-label">
                Iva
                <span className="text-danger">*</span>
              </label>
              <div>
                <Select
                  className="basic-single"
                  options={options}
                  value={{
                    label: `${formData3.ivavalor}`,
                    value: `${formData3.ivavalor}`,
                  }}
                  onChange={(e) => {
                    setValue({ ...formData3, ivavalor: e.value });
                  }}
                />
              </div>
            </div>

            <Button variant="danger" onClick={onCloseModal}>
              Cerrar
            </Button>
            <Button type="submit" disabled={dis}>
              Guardar
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCostos;
