import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const ModalTimbrado = (props) => {
  const { estado, onCloseModal, onReturnValue, defaultValue, isNew } = props;
  const [formData3, setValue] = useState(defaultValue);
  const [dis, setdis] = useState(false);
  const handleSubmit2 = (event) => {
    setdis(true)
    event.preventDefault();
    onReturnValue(formData3, isNew);
  };
  return (
    <>
      <Modal show={estado} onHide={onCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Agregar Timbrado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit2}>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Timbrado
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  value={formData3.timbrado}
                  required
                  onChange={(e) => {
                    setValue({ ...formData3, timbrado: e.target.value });
                  }}
                />
              </div>
            </div>

            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Fecha de Activacion
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="date"
                  className="form-control"
                  required
                  value={formData3.fechaInicial}
                  onChange={(e) => {
                    setValue({ ...formData3, fechaInicial: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Fecha de vencimiento
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="date"
                  className="form-control"
                  required
                  value={formData3.fechaFinal}
                  onChange={(e) => {
                    setValue({
                      ...formData3,
                      fechaFinal: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Sucursal (PF1)
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  required
                  value={formData3.pf1}
                  onChange={(e) => {
                    setValue({
                      ...formData3,
                      pf1: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Punto de Expedicion (PF2)
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="number"
                  className="form-control"
                  required
                  value={formData3.pf2}
                  onChange={(e) => {
                    setValue({
                      ...formData3,
                      pf2: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Factura Inicial
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  required
                  value={formData3.numeroInicial}
                  onChange={(e) => {
                    setValue({ ...formData3, numeroInicial: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="form-group mb-2 row">
              <label className="col-form-label">
                Factura Final
                <span className="text-danger">*</span>
              </label>
              <div className="">
                <input
                  type="text"
                  className="form-control"
                  required
                  value={formData3.numeroFinal}
                  onChange={(e) => {
                    setValue({ ...formData3, numeroFinal: e.target.value });
                  }}
                />
              </div>
            </div>
            {!isNew && (
              <div className="form-group mb-2 row">
                <label className="col-form-label">
                  Esta Activo?
                  <span className="text-danger">*</span>
                </label>
                <div className="">
                  <select  className="form-control" name="selectedFruit" defaultValue={formData3.estaActivo}
                   onChange={(e) => {
                    setValue({ ...formData3, estaActivo: e.target.value });
                  }}>
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                  </select>
                 
                </div>
              </div>

            )}


            <Button variant="danger" onClick={onCloseModal}>
              Cerrar
            </Button>
            <Button type="submit" disabled={dis}>Guardar</Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalTimbrado;
