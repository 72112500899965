
import { jsPDF } from "jspdf";

export default function genPDFDeskjet({ datosfactura, detallefactura,isCredito,TextoNumber,sum1,sum2,impx,impy}) {
  const doc = new jsPDF({
    unit: 'px',
    orientation: 'landscape',
    format: 'A4'
  });
  if (datosfactura.direccion==null)
  {
    datosfactura.direccion=""
  }
  if (datosfactura.telefono==null)
  {
    datosfactura.telefono=""
  }
  datosfactura.iva5 = datosfactura.iva5? datosfactura.iva5:datosfactura.gravado05
  datosfactura.iva10 = datosfactura.iva10? datosfactura.iva10:datosfactura.gravado10
  datosfactura.totalmonto = datosfactura.totalmonto? datosfactura.totalmonto : datosfactura.totalFactura
  datosfactura.ivatotal = datosfactura.ivatotal ? datosfactura.ivatotal : Number(datosfactura.gravado05+datosfactura.gravado10)
  datosfactura.Subtotal5 = datosfactura.Subtotal5 ? datosfactura.Subtotal5: sum1
  datosfactura.Subtotal10 = datosfactura.Subtotal10 ? datosfactura.Subtotal10: sum2
  datosfactura.fechafactura = datosfactura.fechafactura ? datosfactura.fechafactura : new Date().toLocaleDateString()
  const writePage = (offsetX = 0, offsetY=0) => {
    doc.setTextColor(84,126,179);
    /* Separacion entre item del listado*/
    let gapRow = 20;

    /* Cambiar segun booleano para saber si es Contado o Credito */
    let isContado = isCredito
    let casillaX = offsetX + 265 + (isContado ? 28 : 0);

    doc.setFont(undefined, 'bold')
    doc.setFontSize(8)
    /* Casilla */
    doc.text("X", casillaX+5+5,  offsetY+59-1)

    doc.setFontSize(6)
    doc.setFont(undefined, 'normal')

    /* Fecha de emision */
    doc.text(datosfactura.fechafactura, offsetX + 55,  offsetY+59-1)

    /* Razon Social */

    doc.text(datosfactura.razonsocial, offsetX + 68,  offsetY+68-1)

    /* RUC / CI */
    doc.text(datosfactura.ruc, offsetX + 51,  offsetY+76)

    /* Direccion */
    doc.text(datosfactura.direccion, offsetX + 138,  offsetY+76)
    /*tel*/
    doc.text(datosfactura.telefono, offsetX + 263,  offsetY+76)
    /*Bucle para iterar lista de productos */
    for (const [index, obj] of detallefactura.entries()) {
      addRow(obj, offsetX-3, offsetY+106-3 + (index* gapRow))
    }





    /* Subtotal 5 */
    doc.text(String(Math.round(datosfactura.Subtotal5)), offsetX + 230,  offsetY+406+1+6)

    /* Subtotal 10 */
    doc.text(String(Math.round(datosfactura.Subtotal10)), offsetX + 270,  offsetY+406+1+6)

    /* Total a pagar en letras */
    doc.text(TextoNumber, offsetX + 68,  offsetY+408+5+8,
      { maxWidth: 180 })

    /* Total a pagar en numeros */
    doc.text(String(datosfactura.totalmonto), offsetX + 269,  offsetY+408+8+7)

    /* Total iva5 */
    doc.text(String(datosfactura.iva5), offsetX + 100,  offsetY+428-6+8+7)

    /* Total iva10 */
    doc.text(String(datosfactura.iva10), offsetX + 165,  offsetY+428-6+8+7)

    /* Total Total iva */
    doc.text(String(datosfactura.ivatotal), offsetX + 240,  offsetY+428-6+8+7)
  }

  /* Funcion que añade filas a al listado pdf */
  const addRow = (data, x = 0, y) => {
    doc.setFont(undefined, 'bold')
    doc.setFontSize(8)
    doc.text(String(data.cantidad), x + 20, y)
    doc.text(String(data.artcod), x + 42, y)
    doc.setFont(undefined, 'normal')
    doc.setFontSize(8)
    doc.text(data.producto, x + 66, y,
      {maxWidth: 70,
      }
      )
    doc.text(String(data.precio), x + 165+5, y)
    doc.text(String(data.exenta), x + 198+5, y)
    doc.text(String(data.cinco), x + 228+5, y)
    doc.text(String(data.diez), x + 262+7, y)

  }

  writePage(impx,impy)

  writePage(336+impx,impy)

  /*doc.save()*/
  doc.autoPrint()
  doc.output('dataurlnewwindow')
}