import React, {
  Fragment,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import ModalTimbrado from "../sis/ModalOpciones/ModalTimbrado";
import { Col, Card, Table, Button } from "react-bootstrap";
import { getAllTimbrados, saveTimbrado } from "../../../Api/timbrado";

const showReducer = (curShow, state) => {
  switch (state.type) {
    case "SHOW":
      return {
        isShow: true,
        onReturnValue: state.onReturnValue,
      };
    case "SHOW2":
      return {
        isShow2: true,
        onReturnValue: state.onReturnValue,
      };
    case "END":
      return { ...curShow, isShow: false, isShow2: false };
    default:
      return { ...curShow, isShow: false };
  }
};

const OTimbrado = () => {
  const [defaultValue, setRow] = useState([]);
  const [reducer, dispatchReducer] = useReducer(showReducer, {
    isShow: false,
    isShow2: false,
    TimbradoObject: null,
    onReturnValue: null,
  });
  const [listTimbrado, setListTimbrado] = useState([]);
  const onLoadValuesTimbrado = useCallback(async () => {
    try {
      const listResult = await getAllTimbrados();
      setListTimbrado([...listResult]);
    } catch (err) {
      console.log(err);
    }
  });
  useEffect(() => {
    onLoadValuesTimbrado();
  }, []);

  const onAgregarTimbrado = () => {
    setRow("");
    dispatchReducer({
      type: "SHOW",
    });
  };
  const onCloseModal = () => {
    dispatchReducer({ type: "END" });
  };

  const onReturnTimbradodata = async (data, isNew) => {
    dispatchReducer({ type: "END" });
    try {
      const result = await saveTimbrado({ ...data, isNew });
      if (result) {
        if (result) {
          Swal.fire({
            title: "completado",
            html: `Se cargo correctalmente el Talonario`,
            icon: "success",
            confirmButtonColor: 'black',
          });
          const listResult = await getAllTimbrados();
          setListTimbrado([...listResult]);
        }
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `${err}`,
      });
    }
  };
  const OnRowClick = (rowData) => {
    setRow(rowData)
    dispatchReducer({
      type: "SHOW2",
    });
  };

  return (
    <Fragment>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Timbrado </Card.Title>
            <Button onClick={onAgregarTimbrado}>Agregar</Button>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>Timbrado</strong>
                  </th>
                  <th>
                    <strong>Fecha de Activación</strong>
                  </th>
                  <th>
                    <strong>Vencimiento</strong>
                  </th>
                  <th>
                    <strong>PF1</strong>
                  </th>
                  <th>
                    <strong>PF2</strong>
                  </th>
                  <th>
                    <strong>Factura Inicial</strong>
                  </th>
                  <th>
                    <strong>Factura Final</strong>
                  </th>
                  <th>
                    <strong>Activo</strong>
                  </th>
                  <th>
                    <strong>Opciones</strong>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {listTimbrado.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <h3>{item.timbrado}</h3>
                    </td>
                    <td>
                      <h3>{item.fechaInicial}</h3>
                    </td>
                    <td>
                      <h3>{item.fechaFinal}</h3>
                    </td>
                    <td>
                      <h3>{item.pf1}</h3>
                    </td>
                    <td>
                      <h3>{item.pf2}</h3>
                    </td>
                    <td>
                      <h3>{item.numeroInicial}</h3>
                    </td>
                    <td >
                      <h3>{item.numeroFinal}</h3>
                    </td>
                    <td >
                      <h3>{item.estaActivo}</h3>
                    </td>
                    <td >
                      <Link
                        href="#"
                        className="btn btn-primary shadow btn-xs sharp "
                        onClick={() => OnRowClick(item)}
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </Link>
                    </td>
                  </tr >
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
      {reducer.isShow && (
        <ModalTimbrado
          estado={reducer.isShow}
          onCloseModal={onCloseModal}
          onReturnValue={onReturnTimbradodata}
          defaultValue={defaultValue}
          isNew={true} // es nuevo
        />
      )}
      {reducer.isShow2 && (
        <ModalTimbrado
          estado={reducer.isShow2}
          onCloseModal={onCloseModal}
          onReturnValue={onReturnTimbradodata}
          defaultValue={defaultValue}
          isNew={false} // es editado
        />
      )}
    </Fragment>
  );
};
export default OTimbrado;
